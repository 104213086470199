body {
    background-color: lightgray;
}

/* .MuiTableCell-root .MuiTableCell-root {
    border-bottom: 0 !important;
    padding: 0 8px;
}

.MuiTableCell-root .MuiFormControl-root {
    padding: 0 16px;
} */